import React from 'react';
import { createCatalog } from 'library/catalog/create';
import { FormGroup, TextField, Button } from '@material-ui/core';
import { useStyles } from './Style';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrimaryCheckbox from 'components/primaryCheckbox';
import { Paper, CircularProgress} from '@material-ui/core';
import {season_regex} from 'library/constants.js';

export default function CatalogUploader() {
  const classes = useStyles();
	const [userFields, setUserFields] = React.useState({name:"", catalog_type: "initial", brand: "nicethings", ls_enabled: true, wls_enabled: true, is_current: false, drop: null});
  const [is_uploading, setIsUploading] = React.useState(false);

	function handleSubmit(event) {
		event.preventDefault();
    setIsUploading(true);
    if (!season_regex.test(userFields.season)) {
      alert("Season is required, format AW21 or SS21");
      setIsUploading(false);
      return;
    }
		createCatalog(userFields).then(response => {
      setIsUploading(false);
			if (response.status === "success") {
				alert("Awesome! Catalog " + userFields.name + " was created");
			} else if (response.status === "error") {
				alert(response.error);
			} else {
				alert("Problem creating the catalog, try again later!");
			}
		})
	}
	function handleFileAdded(event) {
		event.preventDefault();
		if (event.target.files.length === 0) {return;}
		let file = event.target.files[0];
		setUserFields({...userFields, "catalog_path": file});
	}

  function didChangeServiceDate(event) {
    let date = (new Date(event.target.value)).getTime() / 1000;
    setUserFields({...userFields, "service_date": date});
  }

	return (
    <div className={classes.root}>
      <h1 className={classes.text_h1}>Add a new catalog</h1>
      <div className={classes.form_container}>
        <Paper hidden={!is_uploading}>
          <h2>Uploading new catalog</h2>
          <CircularProgress />
        </Paper>
        <form hidden={is_uploading} className={classes.form} onSubmit={handleSubmit}>
        <FormGroup>
            <TextField className={classes.form_text_field} label="Name" onChange={e => setUserFields({...userFields,name:e.target.value})} />
            {
            /*<RadioGroup row value={userFields.brand} onChange={e => setUserFields({...userFields,brand:e.target.value})}>
             <FormControlLabel value="palomawool" control={<Radio color="primary" />} label="Paloma Wool" />
             <FormControlLabel value="nicethings" control={<Radio color="primary" />} label="Nice things" />
           	</RadioGroup>
            */
            }
            <TextField className={classes.form_text_field} label="Season" onChange={e => setUserFields({...userFields,season:e.target.value})} />
            <RadioGroup className={classes.form_catalog_type} row value={userFields.catalog_type} onChange={e => setUserFields({...userFields,catalog_type:e.target.value})}>
             <FormControlLabel value="initial" control={<Radio color="primary" />} label="Initial" />
             <FormControlLabel value="repo" control={<Radio color="primary" />} label="Reposition" />
             <FormControlLabel value="return" control={<Radio color="primary" />} label="Return" />
           	</RadioGroup>
            <TextField style={{marginBottom: "2em"}} className={classes.form_text_field} label="Drop" onChange={e => setUserFields({...userFields, drop:e.target.value})} />
            <TextField label="Default service date" type="date" onChange={didChangeServiceDate} className={classes.textField} InputLabelProps={{shrink: true,}}/>
            <FormControlLabel control={<PrimaryCheckbox checked={userFields.wls_enabled} onChange={e => setUserFields({...userFields,wls_enabled:e.target.checked})} color="primary"/>} label="Enable in WLS"/>
           	{/* 
            <FormControlLabel control={<PrimaryCheckbox checked={userFields.ls_enabled} onChange={e => setUserFields({...userFields,ls_enabled:e.target.checked})} color="primary"/>} label="Enable in App"/>
            */}
            <FormControlLabel control={<PrimaryCheckbox checked={userFields.is_current} onChange={e => setUserFields({...userFields,is_current:e.target.checked})} color="primary"/>} label="Show by default"/>
            <TextField className={[classes.form_text_field].join(" ")} label="Load excel" type="file" onChange={handleFileAdded} InputLabelProps={{shrink: true}} inputProps={{"accept": ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}} />
            <Button className={classes.button} variant="contained" type="submit" onClick={handleSubmit}>
              Create
            </Button>
        </FormGroup>
        </form>
      </div>

    </div>
  );
}
